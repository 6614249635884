import type {FC} from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next-translate-routes/link'
import {AppBar, Box, Button, Container, IconButton, Link, Toolbar, Typography, ButtonBase, Avatar} from '@mui/material';
import {Menu as MenuIcon} from '../icons/menu';
import {Logo} from './logo';
import {useTranslation} from "next-i18next";
import {useAuth} from "../hooks/use-auth";
import {AccountButton} from "./account-button";
import UserIcon from '@mui/icons-material/AccountCircle';
import {useRef, useState} from "react";
import {SiteLanguagePicker} from "./site-language-picker";
import {useDispatch} from "../store";
import {setShowAuthWall} from "../slices/user-session";

interface MainNavbarProps {
    onOpenSidebar?: () => void;
    hero?: boolean;
}


export const MainNavbar: FC<MainNavbarProps> = (props) => {

    const {onOpenSidebar, hero} = props;
    const {t, i18n} = useTranslation();
    return (
        <AppBar
            elevation={0}
            sx={{
                backgroundColor: hero ? 'transparent' : "background.paper",
                borderBottomColor: 'divider',
                borderBottomStyle: hero ? "none" : {
                    xs: "solid",
                    sm: "solid"
                },
                borderBottomWidth: 1,
                color: 'text.secondary'
            }}
            position={hero ? "static" : "fixed"}
        >
            <Container maxWidth="lg" sx={{
                px: {
                    xs: 1,
                    sm: 1

                },
            }}>
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 48
                    }}
                >

                    {
                        hero ?

                            <SiteLanguagePicker/>
                            :
                            <NextLink
                                href="/"
                                passHref
                            >
                                <a>
                                    <Logo
                                        variant={hero ? "light" : "primary"}
                                        sx={{
                                            display: {
                                                md: 'inline',
                                                xs: 'none'
                                            },
                                            height: 35,
                                            width: 196
                                        }}
                                    />
                                </a>
                            </NextLink>

                    }

                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        color="inherit"
                        onClick={onOpenSidebar}
                        sx={{
                            display: {
                                md: 'none'
                            }
                        }}
                    >
                        <MenuIcon fontSize="small"/>
                    </IconButton>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: {
                                md: 'flex',
                                xs: 'none'
                            }
                        }}
                    >
                        <NextLink
                            href="/diving"
                            passHref
                        >
                            <Link
                                color={hero ? "white" : "textSecondary"}
                                underline="none"
                                variant="subtitle1"
                            >
                                {t("explore-trips")}
                            </Link>
                        </NextLink>
                        <NextLink
                            href="/concept"
                            passHref
                        >
                            <Link
                                color={hero ? "white" : "textSecondary"}
                                sx={{ml: 2}}
                                underline="none"
                                variant="subtitle1"
                            >
                                {t("concept")}
                            </Link>
                        </NextLink>
                        <NextLink
                            href="/contact"
                            passHref
                        >
                            <Link
                                color={hero ? "white" : "textSecondary"}
                                sx={{ml: 2}}
                                underline="none"
                                variant="subtitle1"
                            >
                                Contact
                            </Link>
                        </NextLink>
                        <NextLink
                            href="/articles"
                            passHref
                        >
                            <Link
                                color={hero ? "white" : "textSecondary"}
                                component="a"
                                sx={{ml: 2}}
                                underline="none"
                                variant="subtitle1"
                            >
                                Articles
                            </Link>
                        </NextLink>

                        <AccountButton/>

                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
        ;
};

MainNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};
