import type {FC} from 'react';
import {
    Box,
    Container,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import Image from 'next/image'
import { useTranslation } from 'next-i18next'


interface Item {
    alt: string;
    file: string;
    text: string;
}

export const FooterPre: FC = (props) => {
    const { t } = useTranslation();
    const items: Item[] = [
        {
            alt: "badge",
            file: "footer-quality-badge.svg",
            text: t("footer_top-destinations")
        },
        {
            alt: "school of fish",
            file: "footer-fish-school.svg",
            text: t("footer_right-season")
        },
        {
            alt: "sea fan",
            file: "footer-conservation.svg",
            text: t("footer_with-responsible")
        },
        {
            alt: "wallet",
            file: "footer-wallet.svg",
            text: t("footer_no-fees")
        },
    ]


    return <Box
        sx={{
            backgroundColor: 'white',
            borderTopColor: 'divider',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            pb: 4,
            pt: {
                md: 3,
                xs: 3
            }
        }}
        {...props}
    >
        <Container maxWidth="lg">
            <Grid
                container
                justifyContent={"space-around"}
            >

                {items.map((val, idx) => {

                    return <Grid item key={idx} md={3} xs={12}>
                        <Box p={1} textAlign={"center"}>
                            <Box m={1} textAlign={"center"}>
                                <Image alt={val.alt} src={`/images/${val.file}`} width={48} height={48}/>
                            </Box>
                            <span dangerouslySetInnerHTML={{__html: val.text}}/>

                        </Box>
                    </Grid>

                })}

            </Grid>
        </Container>
    </Box>
}


/*
<div className="container-fluid text-center pb-3"
     style='background-color: #fff; border-top: 1px solid #efefef; color: #444;'>

    <div className='container'>

        <div className="row">
            <div className="col-6 col-lg-3 p-3" style="position: inherit">
                <div className='m-3'>
                    {{partial "svg/footer-quality-badge.svg"}}
                </div>
                {{i18n "footer_top-destinations" | safeHTML}}
            </div>
            <div className="col-6 col-lg-3 p-3" style="position: inherit">
                <div className='m-3'>
                    {{partial "svg/footer-fish-school.svg"}}
                </div>
                {{i18n "footer_right-season" | safeHTML}}
            </div>
            <div className="col-6 col-lg-3 p-3" style="position: inherit">
                <div className='m-3'>
                    {{partial "svg/footer-conservation.svg"}}
                </div>
                {{i18n "footer_with-responsible" | safeHTML}}
            </div>
            <div className="col-6 col-lg-3 p-3" style="position: inherit">
                <div className='m-3'>
                    {{partial "svg/footer-wallet.svg"}}
                </div>
                {{i18n "footer_no-fees" | safeHTML}}
            </div>
        </div>
    </div>
</div>


<div className="container-fluid" style='background-color: #0D677A; color: #fff;'>

    <div className='container'>

        <div className="row">
            <div className="col-12 col-md-6 offset-lg-3 col-lg-4 px-0 py-3 pt-lg-4 text-center" style='font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: bold;'>
                <div className='mr-2'>{{partial "svg/help-you.svg"}} {{i18n "can-help-you"}}</div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 p-3 pt-lg-2 px-0 text-center">
                <span
                    style='font-weight: bold; font-size: 1.8rem; line-height: 3.4rem; color: #fff;'>{{i18n "seacrush-tel"}}</span><br/>
                <span style='letter-spacing: 0.065em'>{{i18n "or"}} <a style='color:#fff; font-weight: bold'
                                                                       href='{{ with .Site.GetPage "page" "contact" }}{{ .RelPermalink }}{{ end }}'>{{i18n "send-us-a-message"}}</a></span>
            </div>
        </div>
        <div className="row">
            <div className='col-12 offset-lg-3 col-lg-8 pt-1'>
                <hr className='m-0' style='border-top:1px solid #c8c8c8'/>
            </div>
        </div>
    </div>
</div>
*/
