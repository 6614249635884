import type {FC} from 'react';
import {
    Box,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography, Link
} from '@mui/material';
import {MinusOutlined as MinusOutlinedIcon} from '../icons/minus-outlined';
import {LogoOnly} from './logo-only';
import {SiteLanguagePicker} from "./site-language-picker";
import {useTranslation} from "next-i18next";

import NextLink from 'next-translate-routes/link'

import footerDataJson from "../generated/footer-data.json"
import {useMemo} from "react";
import {localize} from "../lib/util";
import React from 'react';


const socialButtons = [
    {
        file: "facebook.svg",
        alt: "facebook",
        title: "SeaCrush on Facebook",
        url: "https://www.facebook.com/SeaCrush/"
    },
    {
        file: "instagram.svg",
        alt: "instagram",
        title: "SeaCrush on Instagram",
        url: "https://www.instagram.com/seacrushexpeditions/"
    },
    {
        file: "rss.svg",
        alt: "Rss Feed",
        title: "SeaCrush Blog Articles",
        url: "/en/articles"
    },


]

const basicLinks = [
    {
        path: {
            en: "/about",
            fr: "/a-propos"
        },
        titleKey: "title-about"
    },
    {
        path: {
            en: "/privacy",
            fr: "/politique-de-confidentialite"
        },
        titleKey: "title-privacy"
    },
    {
        path: {
            en: "/terms",
            fr: "/conditions-utilisation"
        },
        titleKey: "title-terms"
    }
]


export const Footer: FC = (props) => {

    const {t, i18n} = useTranslation()


    const footerData = useMemo(() => {
        return localize(footerDataJson, [i18n.language])
    }, [i18n.language])


    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'primary.dark',
                    borderTopColor: 'divider',
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    pb: 6,
                    pt: {
                        md: 6,
                        xs: 6
                    }
                }}
                {...props}
            >
                <Container maxWidth="lg">

                    <Grid
                        container
                        spacing={3}
                    >

                        <Grid
                            item
                            md={3}
                            sm={3}
                            sx={{
                                textAlign: "center",
                                order: {
                                    md: 0,
                                    xs: 2
                                }
                            }}
                        >

                            <Box>
                                <LogoOnly variant={"light"}/>
                            </Box>
                            <SiteLanguagePicker/>


                        </Grid>
                        <Grid
                            item
                            sm={9}
                        >

                            <Grid
                                container
                                spacing={3}
                            >
                                {footerData.map((continent: any, index: number) => (
                                    <Grid
                                        item
                                        key={index}
                                        md={3}
                                        sm={3}
                                        sx={{
                                            order: {
                                                md: index + 2,
                                                xs: index + 1
                                            }
                                        }}
                                        xs={12}
                                    >
                                        <Typography
                                            color="primary.contrastText"
                                            variant="overline"
                                        >
                                            {continent.title}
                                        </Typography>
                                        <List disablePadding>
                                            {continent.countries.map((country: any, idx: number) => (
                                                <ListItem
                                                    disableGutters
                                                    key={idx}
                                                    sx={{
                                                        pb: 0,
                                                        pt: 0
                                                    }}
                                                >
                                                    <ListItemAvatar
                                                        sx={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            minWidth: 0,
                                                            mr: 0.5
                                                        }}
                                                    >
                                                        <MinusOutlinedIcon color="primary"/>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        sx={{color: "#fff"}}
                                                        primary={(
                                                            <NextLink
                                                                href={`/diving/${country.slug.current}`}
                                                                passHref
                                                            ><Link
                                                                   color="primary.contrastText"
                                                                   variant="subtitle2">
                                                                {country.title}
                                                            </Link>
                                                            </NextLink>
                                                        )}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box
                pt={3}
                pb={2}
                sx={{backgroundColor: "#383838", borderTop: "1px solid #6d6d6d"}}
            >
                <Container maxWidth={"sm"}>
                    <Grid container
                          justifyContent={"space-around"}>

                        {socialButtons.map((val, idx) => {

                            return <Grid
                                key={"social-" + idx}
                                item
                                md={3}
                                sm={4}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    order: {
                                        md: 1,
                                        xs: 4
                                    }
                                }}
                                xs={12}
                                alignItems={"center"}
                            >

                                <a style={{display: "inline-block"}}
                                   href={val.url}
                                   title={val.title}
                                   rel="noopener noreferrer"
                                   target="_blank">
                                    <img
                                        src={`/images/${val.file}`}
                                        style={{
                                            width: 48,
                                            height: 48,
                                            verticalAlign: "text-bottom",
                                            filter: "brightness(45%)"
                                        }} alt={val.alt}/>
                                </a>

                            </Grid>

                        })}

                    </Grid>
                </Container>

                <Typography textAlign={"center"} sx={{mt: 3}} variant={"body1"} color="#8d8d8d">

                    {basicLinks.map<React.ReactNode>((val, idx) => {
                        // @ts-ignore
                        return <NextLink href={val.path[i18n.language]} key={"link-" + idx}>
                            <Link
                                key={idx}
                                style={{cursor: "pointer"}}
                                color="#8d8d8d"
                                variant="body1">
                                {t(val.titleKey)}
                            </Link>
                        </NextLink>

                    }).reduce((prev, curr, idx) => [prev, <React.Fragment key={idx}>
                        &nbsp;-&nbsp;
                    </React.Fragment>, curr])}





                </Typography>

            </Box>

            <Box textAlign={"center"}
                 sx={{
                     backgroundColor: "#f6f6f6",
                     borderTop: "1px solid #6d6d6d"
                 }}>
                <Typography
                    color="textSecondary"
                    variant="caption"
                >
                    &copy; {new Date().getFullYear()} SeaCrush SASU, Reg #: IM078170016 {t("rights-reserved")}.
                </Typography>
            </Box>
        </>
    )
};
