import type {FC} from 'react';
import NextLink from 'next/link';
import {useRouter} from 'next-translate-routes/router';
import toast from 'react-hot-toast';
import {
    Avatar,
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography, ButtonBase, Badge
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import WarningIcon from '@mui/icons-material/Warning';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import AccountIcon from "@mui/icons-material/AccountCircle";

import {useAuth} from "../hooks/use-auth";
import {useTranslation} from "next-i18next";
import {useDispatch, useSelector} from "../store";
import {setShowOnboarding} from "../slices/onboarding-slice";
import UserIcon from "@mui/icons-material/AccountCircle";
import {useRef, useState} from "react";
import {setShowAuthWall} from "../slices/user-session";


export const AccountButton: FC = () => {

    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [openPopover, setOpenPopover] = useState<boolean>(false);

    const handleOpenPopover = (): void => {
        setOpenPopover(true);
    };

    const handleClosePopover = (): void => {
        setOpenPopover(false);
    };

    const router = useRouter();

    const {logout, user, isAuthenticated} = useAuth();

    const {t} = useTranslation()

    const {showAuthWall} = useSelector((state) => state.userSession);

    const dispatch = useDispatch()

    const handleLogout = async (): Promise<void> => {
        try {
            await logout();
            router.push('/').catch(console.error);
        } catch (err) {
            console.error(err);
            toast.error('Unable to logout.');
        }
    };

    return (<>
            <Box
                component={ButtonBase}
                onClick={handleOpenPopover}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: 2
                }}
            >
                <Badge color="secondary" variant="dot" invisible={openPopover || isAuthenticated || showAuthWall}
                       overlap="circular"
                >
                    <Avatar
                        sx={{
                            height: 40,
                            width: 40
                        }}
                        src={user?.photoUrl}
                    >
                        <UserIcon fontSize="large"/>
                    </Avatar>
                </Badge>
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                // keepMounted
                onClose={handleClosePopover}
                open={openPopover}
                PaperProps={{sx: {width: 220, borderRadius: 1, mt: 1}}}
                transitionDuration={100}
            >
                {!isAuthenticated && <>
                    <Box sx={{my: 1}}>
                        <MenuItem onClick={(e) => {
                            dispatch(setShowAuthWall(true))
                            handleClosePopover()
                        }}>
                            <ListItemText
                                primary={(
                                    <Typography variant="body1" sx={{fontWeight: "bold"}}>
                                        {t("signup")}
                                    </Typography>
                                )}
                            />
                        </MenuItem>
                        <MenuItem onClick={(e) => {
                            dispatch(setShowAuthWall(true))
                            handleClosePopover()
                        }}>
                            <ListItemText
                                primary={(
                                    <Typography variant="body1">
                                        {t("login")}
                                    </Typography>
                                )}
                            />
                        </MenuItem>
                    </Box>
                </>
                }

                {isAuthenticated && <>
                    <Box
                        sx={{
                            alignItems: 'center',
                            p: 2,
                            display: 'flex'
                        }}
                    >
                        <Avatar
                            src={user?.photoUrl}
                            sx={{
                                height: 40,
                                width: 40
                            }}
                        >

                            <WarningIcon fontSize="small"/>
                        </Avatar>
                        <Box
                            sx={{
                                ml: 1
                            }}
                        >
                            <Typography variant="body1">
                                {user?.name}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {user?.certBody} {user?.certLevel}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box sx={{my: 1}}>
                        {/*<NextLink*/}
                        {/*    */}
                        {/*    passHref*/}
                        {/*>*/}
                        <MenuItem component="a" href={`/u/${user?.username}`}>
                            <ListItemIcon>
                                <AccountIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <Typography variant="body1">
                                        {t("profile")}
                                    </Typography>
                                )}
                            />
                        </MenuItem>
                        {/*</NextLink>*/}
                        <NextLink
                            href="/settings"
                            passHref
                        >
                            <MenuItem component="a">
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={(
                                        <Typography variant="body1">
                                            {t("settings")}
                                        </Typography>
                                    )}
                                />
                            </MenuItem>
                        </NextLink>
                        <Divider/>

                        <MenuItem onClick={(e) => {
                            dispatch(setShowOnboarding(true))
                            handleClosePopover()
                        }}>
                            <ListItemIcon>
                                <SettingsIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <Typography variant="body1">
                                        Show Onboarding
                                    </Typography>
                                )}
                            />
                        </MenuItem>
                        <Divider/>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <Typography variant="body1">
                                        Logout
                                    </Typography>
                                )}
                            />
                        </MenuItem>
                    </Box>
                </>}

            </Popover>
        </>
    );
};
