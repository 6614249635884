import {FC} from "react";
import {useRouter} from "next-translate-routes/router";
import {Typography, Box, Select, MenuItem} from "@mui/material";
import {useTranslation} from "next-i18next";
import {SelectChangeEvent} from "@mui/material/Select";
import {fileUrlToUrl} from "next-translate-routes";

interface SiteLanguagePickerProps {

}

export const SiteLanguagePicker: FC<SiteLanguagePickerProps> = (props) => {

    const {t, i18n} = useTranslation()

    const {locale, locales, pathname, query, route, push} = useRouter()


    const handleChange = (e: SelectChangeEvent) => {

        push({pathname, query}, undefined, {locale: e.target.value})


    }

    return <Select

        sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': {border: 0},
            "& .MuiSvgIcon-root": {
                color: "white",
            },

    }}


        onChange={handleChange}
        value={i18n.language}
        renderValue={(val) => {
            return <Box>
                <img src={`/images/flags/lang/${val}.png`}
                     srcSet={`/images/flags/lang/${val}.png, /images/flags/lang/${val}@2x.png 2x`}
                     style={{
                         height: 28,
                         width: 28,
                         marginRight: 6,
                         verticalAlign: "middle"
                     }}
                     alt={t(val)}/><Typography display={"inline"}
                                               color={"white"}> {t(val)}</Typography>
            </Box>
        }}
    >
        {locales?.filter((val) => val !== 'default').map((newLocale, idx) => {

            return <MenuItem key={idx} value={newLocale}>{t(newLocale)}</MenuItem>
        })}


    </Select>

}
